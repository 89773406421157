<script setup lang="ts">
import { FilterMode } from '@critical-dates'
import { useApiPortfolioInfinite } from '@portfolio'

withDefaults(
  defineProps<{
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const route = useRoute('division-companyId-critical-dates-triggers-triggerId')
const divisionId = getCurrentDivisionId()

const searchTerm = ref('')
const search = useDebounceFn((value: string) => (searchTerm.value = value), 500)
const {
  data: leasesResponse,
  isFetching,
  fetchNextPage,
  hasNextPage,
} = useApiPortfolioInfinite(
  divisionId,
  computed(() => ({ searchTerm: searchTerm.value })),
)

const leaseDocuments = computed(
  () =>
    leasesResponse.value?.pages.flatMap((page) => page?.result?.items ?? []) ??
    [],
)

const activeTab = ref<FilterMode>(
  route.query.leaseId ? FilterMode.INCLUDE_LEASES : FilterMode.ALL_LEASES,
)
</script>

<template>
  <div class="grid flex-none flex-col gap-2">
    <small
      :class="[
        'formkit-label formkit-invalid:text-danger block text-sm font-medium text-gray-900 transition duration-100 dark:text-white',
        disabled && 'opacity-40',
      ]"
    >
      Select the leases
    </small>
    <div class="flex w-full">
      <ButtonGroup
        id="buttonGroup"
        v-model="activeTab"
        :tooltip="{
          placement: 'top',
          class:
            'bg-warning text-sm backdrop-blur-2xl backdrop-saturate-[180%] border-warning text-sm text-black',
        }"
        :disabled="disabled"
      >
        <ButtonGroupItem
          :name="FilterMode.ALL_LEASES"
          :tooltip="{
            content: 'This trigger will be applied to all leases',
          }"
        >
          All leases
        </ButtonGroupItem>
        <ButtonGroupItem
          :name="FilterMode.EXCLUDE_LEASES"
          :tooltip="{
            content:
              'This trigger will be applied to all leases except the ones selected',
          }"
        >
          Exclude specific leases
        </ButtonGroupItem>
        <ButtonGroupItem
          :name="FilterMode.INCLUDE_LEASES"
          :tooltip="{
            content: 'This trigger will be applied to the selected leases only',
          }"
        >
          Selected leases only
        </ButtonGroupItem>
      </ButtonGroup>
    </div>
  </div>

  <div>
    <FormKit type="group" name="filters">
      <FormKit
        v-model="activeTab"
        type="hidden"
        name="filterMode"
        :value="
          route.query.leaseId
            ? FilterMode.INCLUDE_LEASES
            : FilterMode.ALL_LEASES
        "
      />
      <FormKit
        v-if="activeTab !== FilterMode.ALL_LEASES"
        type="taglist"
        :label="
          activeTab === FilterMode.EXCLUDE_LEASES
            ? 'Exclude leases'
            : 'Select leases'
        "
        :help="
          activeTab === FilterMode.EXCLUDE_LEASES
            ? 'Select the leases to exclude from all your leases. New leases will be automatically added to this trigger.'
            : 'Select specific leases to apply this trigger. New leases created after this trigger won\'t be included.'
        "
        name="leaseIds"
        placeholder="Search"
        :searchable="true"
        :internal-search="false"
        :loading="isFetching"
        label-by="name"
        track-by="id"
        :options="leaseDocuments"
        :value="route.query.leaseId ? [route.query.leaseId] : undefined"
        hide-selected
        hide-display-value
        :has-next-page="hasNextPage"
        validation="required|length:1"
        @search="search"
        @fetch-next-page="fetchNextPage()"
      >
        <template #prefix>
          <div class="flex items-center pl-2">
            <Icon name="search" class="text-xl" filled />
          </div>
        </template>

        <template #taggeditem="{ label, option }">
          <span
            class="flex items-center gap-2 overflow-hidden"
            :class="{
              'font-bold text-gray-100': option.id === route.query.leaseId,
            }"
          >
            <Icon name="documents-file-text" class="text-xl" />
            <span
              class="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm"
            >
              {{ label }}
            </span>
          </span>
        </template>
      </FormKit>
    </FormKit>
  </div>
</template>
